import ResultsBoxAllBets from "../Results/ResultsBoxAllBets";
import FadeIn from "react-fade-in";
import {useEffect, useState} from "react";
import label from "../../services/Translation/lang";
import moment from 'moment';

export default function AllBets(props) {

  const LIMITALLBETS = 7;
  // eslint-disable-next-line no-unused-vars
  const [pagIndex, setPagIndex] = useState(0);
  const [pagSize, setPagSize] = useState(LIMITALLBETS);
  const [loadMoreVisible, setLoadMoreVisible] = useState('none');
  const pag = (array, page_size, page_number) => {
    return array.slice(page_number * page_size, page_number * page_size + page_size);
  };

  useEffect(() => {
    if (props.allBetsItems.length < LIMITALLBETS) {
      setPagSize(LIMITALLBETS);
      setLoadMoreVisible('none');
    } else {
      setTimeout(()=> { setLoadMoreVisible('block') }, 3000)
    }
  }, [props.allBetsItems.length, pagSize]);

  return (
    <div className="results-tab">
      <FadeIn>
        {
        pag(props.allBetsItems, pagSize, pagIndex).map((result, index) => {
          if(props.roundID > result.round_id || props.roundID < result.round_id) {
            return null;
          };
          return (
            <ResultsBoxAllBets
              noRecord={true}
              key={index}
              img={result.avatar_id}
              username={result.username}
              flag={result.country_code}
              balls={result.numbers}
              indexArray={props.indexArray}
              net={props.net}
              indexArrayQuota={props.indexArrayQuota}
              date={result.date}
              // time={result.time}
              time={moment.utc(`${result.date} ${result.time}`, 'YYYY-MM-DD HH:mm').local().format('HH:mm')}
              roundNumber={result.round_id}
              stake={result.bet}
              baseStake={result.bet_eur}
              win={result.win}
              currency={result.currency_code}
              bonusBals={result.bonusBals}
              userCurrencyCode={props.userCurrencyCode}
            />
          );
        })}
      </FadeIn>
      <button className="your-ticket-clear" style={{display: loadMoreVisible, marginLeft: '35%'}} onClick={(e) => {
        setPagSize((prevState) => {
          if (prevState < (LIMITALLBETS*4) ) {
            if ((prevState + LIMITALLBETS) >= props.allBetsItems.length-1) {
              return prevState;
            } else {
              return (prevState + LIMITALLBETS);
            }
          } else {
            return prevState;
          }
        });
      }}>
        {label.t("load_more")}
      </button>
    </div>
  );
}

import FadeIn from "react-fade-in";
import Flag from "react-world-flags";
import storage from "../../services/Storage/Storage";
import handleNumber from "../../services/Helpers/HandleNumber";
import {currencyFormatter} from "../../services/Helpers/NumberFormatter";
import label from "../../services/Translation/lang";
import moment from 'moment';

export default function TopOdds(props) {
  return (
    <div className="results-tab">
      <div className="topWinH">
        <div style={{width: '70%'}}> {label.t('time_frame')} </div>
        <select onChange={(e) => {
          // console.log('call update from here', e.target.value);
          if(e.target.value === 'week') {
            props.net.requestTopOddsWeekly();
          } else if(e.target.value === 'month') {
            props.net.requestTopOddsMonthly();
          } else if(e.target.value === 'year') {
            props.net.requestTopOddsYearly();
          }
        }} name="timeframe" id="timeframe"
          style={
            {
              background: 'gold',
              width: '30%',
              border: 'none',
              outline: 'none'
            }}>
          <option value="week"> {label.t('week')} </option>
          <option value="month"> {label.t('month')} </option>
          <option value="year"> {label.t('year')} </option>
        </select>
      </div>
      <FadeIn className="my-results-reversed">
        {props.topWinsItems.sort((a, b) => a.winEur > b.winEur ? 1 : -1).map((item, index) => {
          return (
            <div className="results-box" key={index}>
              <div className="results-box-heder">
                <div className="results-user-avatar">
                  <img src="/avatars/avatar-1.png" alt={item.username} />
                </div>
                <div className="results-user-name">{item.username}</div>
                <div className="results-user-flag">
                  {(storage.get('flagPreview') === false ? null : <Flag code={props.flag} height="10" width="14" />)}
                </div>
              </div>
              <div className="results-balls-holder">
                <div className="topwin-info">
                  <span>{label.t('round')}:</span>
                  {item.roundId}
                </div>
                <div className="topwin-info">
                  <span> {label.t('win')}: </span>
                  <div>
                  {
                    sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter((item.winEur * parseFloat(storage.get('currency_exchange_rate'))).toFixed(props.net.noOfDecimals), "###,###"):
                    (item.winEur * parseFloat(storage.get('currency_exchange_rate'))).toFixed(props.net.noOfDecimals)
                    }
                    <span className="header-credit-currency">{props.userCurrencyCode}</span>
                  </div>
                </div>
                <div className="topwin-info">
                  <span> {label.t('ticket_id')}: </span>
                  {item.ticketId}
                </div>
                <div className="topwin-info">
                  <span> {label.t('date')}: </span>
                  {item.createdDate}
                </div>
                <div className="topwin-info">
                  <span>{label.t('time')}:</span>
                  {moment.utc(`${item.createdDate} ${item.createdTime}`, 'DD.MM. HH:mm').local().format('HH:mm')}
                </div>
              </div>
              <label className="topwin-info"> {label.t('ticket_numbers')}</label>
              <div className="results-balls-holder">
                {item.numbers.map((ball, index) => {
                  return (
                    <div key={index}>
                      <div className={"results-ball " + handleNumber(ball)}>
                        {ball}
                      </div>
                    </div>)
                })}
              </div>
            </div>
          )
        })}
      </FadeIn>
    </div>
  )
}
